import React, { useState, useEffect } from 'react'
import BaseLayout, { styles } from '../Layouts/BaseLayout'
import { Helmet } from 'react-helmet'

type PaymentType = '返済期間' | '毎月の返済額'

interface CalculationHistory {
    id: string
    housePrice: number
    downPayment: number
    interestRate: number
    paymentType: PaymentType
    paymentPeriodYears: number
    paymentPeriodMonths: number
    monthlyPayment: number
    loanAmount: number
    totalInterest: number
}

declare global {
    interface Window {
        dataLayer: any[]
    }
}

const MortgageCalculator = () => {
    const [housePrice, setHousePrice] = useState('')
    const [downPayment, setDownPayment] = useState('')
    const [interestRate, setInterestRate] = useState('')
    const [paymentType, setPaymentType] = useState<PaymentType>('毎月の返済額')
    const [paymentPeriodYears, setPaymentPeriodYears] = useState('')
    const [paymentPeriodMonths, setPaymentPeriodMonths] = useState('')
    const [monthlyPayment, setMonthlyPayment] = useState('')
    const [history, setHistory] = useState<CalculationHistory[]>([])
    const [isFormValid, setIsFormValid] = useState(false)
    const [calculationResult, setCalculationResult] = useState<{
        housePrice: number;
        downPayment: number;
        loanAmount: number;
        monthlyPayment: number;
        paymentPeriod: number;
        totalInterest: number;
    } | null>(null);

    // 入力値の検証を行う関数
    const validateInputs = (
        principal: number,
        monthlyRate: number,
        payment: number,
        totalMonths?: number
    ): string | null => {
        // マイナス値のチェックのみ
        if (principal < 0) {
            return '借入額は0以上の値を入力してください。';
        }
        if (monthlyRate < 0) {
            return '金利は0以上の値を入力してください。';
        }

        // 毎月の返済額は利息の10%以上であることをチェック
        const minMonthlyPayment = principal * monthlyRate * 0.1;

        if (payment > 0 && payment < minMonthlyPayment) {
            return `返済額が小さすぎます。`;
        }

        if (totalMonths !== undefined && totalMonths < 0) {
            return '返済期間は0以上の値を入力してください。';
        }

        return null;
    };

    useEffect(() => {
        setIsFormValid(
            housePrice !== '' &&
            downPayment !== '' &&
            interestRate !== '' &&
            Number(housePrice) > 0 &&
            Number(interestRate) > 0 &&
            ((paymentType === '返済期間' && (paymentPeriodYears !== '' || paymentPeriodMonths !== '')) ||
                (paymentType === '毎月の返済額' && monthlyPayment !== '' && Number(monthlyPayment) > 0))
        )
    }, [housePrice, downPayment, interestRate, paymentType, paymentPeriodYears, paymentPeriodMonths, monthlyPayment])

    const calculateMonthlyPayment = (principal: number, monthlyRate: number, totalMonths: number): number => {
        if (monthlyRate === 0) return principal / totalMonths;
        return (principal * monthlyRate * Math.pow(1 + monthlyRate, totalMonths)) / (Math.pow(1 + monthlyRate, totalMonths) - 1)
    }

    const calculateLoanTerm = (principal: number, monthlyRate: number, payment: number): number => {
        if (monthlyRate === 0) return principal / payment;
        if (payment <= principal * monthlyRate) {
            throw new Error('返済額が少なすぎます');
        }
        const term = Math.ceil(Math.log(payment / (payment - principal * monthlyRate)) / Math.log(1 + monthlyRate));
        if (!Number.isFinite(term) || term <= 0) {
            throw new Error('有効な返済期間を計算できません');
        }
        return term;
    }

    const calculateAmortizationSchedule = (principal: number, monthlyRate: number, payment: number, totalMonths: number) => {
        let balance = principal
        let totalInterest = 0

        for (let i = 0; i < totalMonths; i++) {
            const interestPayment = balance * monthlyRate
            const principalPayment = payment - interestPayment

            totalInterest += interestPayment
            balance -= principalPayment

            if (balance < 0) {
                balance = 0
                break
            }
        }

        return { totalInterest, finalBalance: balance }
    }

    const calculateMortgage = () => {
        try {
            const housePriceValue = Number(housePrice);
            const downPaymentValue = Number(downPayment);
            const principal = housePriceValue * 10000 - downPaymentValue * 10000;
            const monthlyRate = Number(interestRate) / 100 / 12;
            let totalMonths: number;
            let payment: number;

            if (principal <= 0) {
                alert('借入額は0より大きい値にしてください');
                return;
            }

            if (paymentType === '返済期間') {
                totalMonths = (Number(paymentPeriodYears) || 0) * 12 + (Number(paymentPeriodMonths) || 0);

                // 入力値の検証
                const validationError = validateInputs(principal, monthlyRate, 0, totalMonths);
                if (validationError) {
                    alert(validationError);
                    return;
                }

                payment = calculateMonthlyPayment(principal, monthlyRate, totalMonths);
            } else {
                payment = Number(monthlyPayment) * 10000;

                // 入力値の検証
                const validationError = validateInputs(principal, monthlyRate, payment);
                if (validationError) {
                    alert(validationError);
                    return;
                }

                // 返済期間の計算に制限を設ける
                const maxMonths = 35 * 12; // 最大35年
                try {
                    totalMonths = calculateLoanTerm(principal, monthlyRate, payment);
                    if (totalMonths > maxMonths) {
                        alert('返済期間が35年を超えています。毎月の返済額を増やしてください。');
                        return;
                    }
                } catch (error) {
                    alert('この返済額では完済できません。より高い返済額を入力してください。');
                    return;
                }
            }

            const { totalInterest } = calculateAmortizationSchedule(principal, monthlyRate, payment, totalMonths);

            setCalculationResult({
                housePrice: housePriceValue,
                downPayment: downPaymentValue,
                loanAmount: principal,
                monthlyPayment: payment,
                paymentPeriod: totalMonths,
                totalInterest: totalInterest,
            });

            try {
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'mortgage_calculation',
                        housePrice: housePriceValue,
                        downPayment: downPaymentValue,
                        interestRate: Number(interestRate),
                        paymentType: paymentType,
                        loanAmount: principal,
                        monthlyPayment: payment,
                        totalInterest: totalInterest
                    });
                }
            } catch (error) {
                console.warn('dataLayer error:', error);
            }

            const newHistoryItem: CalculationHistory = {
                id: Date.now().toString(),
                housePrice: housePriceValue,
                downPayment: downPaymentValue,
                interestRate: Number(interestRate),
                paymentType: paymentType,
                paymentPeriodYears: Math.floor(totalMonths / 12),
                paymentPeriodMonths: totalMonths % 12,
                monthlyPayment: payment,
                loanAmount: principal,
                totalInterest: totalInterest
            };

            setHistory(prevHistory => [newHistoryItem, ...prevHistory]);

        } catch (error) {
            console.error('Calculation error:', error);
            alert('計算中にエラーが発生しました。入力値を確認してください。');
        }
    }

    const deleteHistoryItem = (id: string) => {
        setHistory(prevHistory => prevHistory.filter(item => item.id !== id))
    }

    const resetHistoryAndReload = () => {
        window.location.reload()
    }

    const title = "住宅ローン計算機"
    const description = "住宅ローンの返済期間や毎月の返済額によって、最終的に支払う金額や利息を計算します。利息の計算には「元利均等返済方式」を採用しています。"

    const PaymentTypeSelector = () => (
        <div style={{ display: 'flex', marginBottom: '10px' }}>
            {['毎月の返済額', '返済期間'].map((type) => (
                <button
                    key={type}
                    style={{
                        ...styles.button,
                        flex: 1,
                        padding: '10px',
                        backgroundColor: paymentType === type ? '#4CAF50' : '#f0f0f0',
                        color: paymentType === type ? 'white' : 'black',
                    }}
                    onClick={() => setPaymentType(type as PaymentType)}
                >
                    {type}
                </button>
            ))}
        </div>
    )

    return (
        <BaseLayout
            title={title}
            description={description}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
            </Helmet>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="housePrice">住宅価格 (万円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="housePrice"
                    value={housePrice}
                    onChange={(e) => setHousePrice(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="downPayment">頭金 (万円):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="downPayment"
                    value={downPayment}
                    onChange={(e) => setDownPayment(e.target.value)}
                />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="interestRate">年金利 (%):</label>
                <input
                    style={styles.input}
                    type="number"
                    id="interestRate"
                    value={interestRate}
                    onChange={(e) => setInterestRate(e.target.value)}
                />
            </div>
            <PaymentTypeSelector/>
            {paymentType === '毎月の返済額' ? (
                <div style={styles.inputGroup}>
                    <label style={styles.label} htmlFor="monthlyPayment">毎月の返済額 (万円):</label>
                    <input
                        style={styles.input}
                        type="number"
                        id="monthlyPayment"
                        value={monthlyPayment}
                        onChange={(e) => setMonthlyPayment(e.target.value)}
                    />
                </div>
            ) : (
                <div style={styles.inputGroup}>
                    <label style={styles.label}>返済期間:</label>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <input
                            style={{ ...styles.input, flex: 1 }}
                            type="number"
                            placeholder="年"
                            value={paymentPeriodYears}
                            onChange={(e) => setPaymentPeriodYears(e.target.value)}
                        />
                        <input
                            style={{ ...styles.input, flex: 1 }}
                            type="number"
                            placeholder="月"
                            value={paymentPeriodMonths}
                            onChange={(e) => setPaymentPeriodMonths(e.target.value)}
                        />
                    </div>
                </div>
            )}
            <button
                style={{
                    ...styles.button,
                    opacity: isFormValid ? 1 : 0.5,
                    cursor: isFormValid ? 'pointer' : 'not-allowed'
                }}
                onClick={calculateMortgage}
                disabled={!isFormValid}
            >
                計算
            </button>
            {calculationResult && (
                <div style={styles.result}>
                    <p>住宅価格: {calculationResult.housePrice.toFixed(2)}万円</p>
                    <p>頭金: {calculationResult.downPayment.toFixed(2)}万円</p>
                    <p>借入額: {(calculationResult.loanAmount / 10000).toFixed(2)}万円</p>
                    <p>毎月の返済額: {(calculationResult.monthlyPayment / 10000).toFixed(2)}万円</p>
                    <p>返済期間: {Math.floor(calculationResult.paymentPeriod / 12)}年 {Math.ceil(calculationResult.paymentPeriod % 12)}ヶ月</p>
                    <p>総支払利息: {(calculationResult.totalInterest / 10000).toFixed(2)}万円</p>
                    <p>ローン支払総額: {((calculationResult.loanAmount + calculationResult.totalInterest) / 10000).toFixed(2)}万円</p>
                    <p>総支払額: {((calculationResult.housePrice * 10000 + calculationResult.totalInterest) / 10000).toFixed(2)}万円</p>
                </div>
            )}

            {history.length > 0 && (
                <div style={{marginTop: '20px'}}>
                    <h3>計算履歴</h3>
                    <ul style={{listStyleType: 'none', padding: 0}}>
                        {history.map((item) => (
                            <li
                                key={item.id}
                                style={{
                                    marginBottom: '10px',
                                    padding: '10px',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '5px',
                                    position: 'relative',
                                }}
                            >
                                住宅価格: {item.housePrice}万円, 頭金: {item.downPayment}万円, 金利: {item.interestRate}%
                                <br/>
                                借入額: {(item.loanAmount / 10000).toFixed(2)}万円,
                                毎月の返済額: {(item.monthlyPayment / 10000).toFixed(2)}万円
                                <br/>
                                返済期間: {Math.floor(item.paymentPeriodYears)}年 {Math.ceil(item.paymentPeriodMonths)}ヶ月,
                                総支払利息: {(item.totalInterest / 10000).toFixed(2)}万円
                                <button
                                    onClick={() => deleteHistoryItem(item.id)}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '5px',
                                        fontSize: '16px',
                                        color: '#888',
                                    }}
                                    aria-label="削除"
                                >
                                    ×
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button style={styles.button} onClick={resetHistoryAndReload}>計算履歴をリセット</button>
                </div>
            )}
        </BaseLayout>
    )
}

export default MortgageCalculator
